@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

img {
    width: 90vw;
    height: 60vh;
}

body {
    font-family: Helvetica, Arial, sans-serif;
    text-shadow: none;
    font-size: 1.2rem;
    line-height: 1.5;
    color: #555;
    background-color: rgba(227, 229, 231, 0.678);
    font-family: 'Noto Serif', serif;
    -webkit-user-select: none;
    /* overflow: hidden; */
}

/* Welcome Page Css Start */
.spinner-box {
    width: 100vw;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: transparent;
}

.spinner-box img {
    width: 200px;
    height: 200px;
    margin-bottom: 30px;
}


/* PULSE BUBBLES */

.pulse-container {
    width: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pulse-bubble {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #192f59;
}

.pulse-bubble-1 {
    animation: pulse .5s ease 0s infinite alternate;
}

.pulse-bubble-2 {
    animation: pulse .5s ease .2s infinite alternate;
}

.pulse-bubble-3 {
    animation: pulse .5s ease .4s infinite alternate;
}

@keyframes pulse {
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: .25;
        transform: scale(.75);
    }
}

@media screen and (max-width:530px) {

    .spinner-box img {
        width: 90px;
        height: 90px;
        margin-bottom: 20px;
    }

    .pulse-container {
        width: 80px;
    }

    .pulse-bubble {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #192f59;
    }

}

/* Welcome Page Css End */






/* Navbar Css Start */

.navTop {
    display: flex;
}

.navTop img {
    padding: 10px;
    width: 84px;
    height: 74px;
}

.logo-text {
    padding: 8px;
}

.logo-text h3 {
    line-height: 1;
    font-weight: bold;
}

.navbar {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.navbar-toggler {
    padding: 0rem 0.3rem;
    font-size: 1rem;
}

/* .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0;
    margin-bottom: 0;
} */

.navbar-nav .dropdown-menu {
    position: static;
    margin: 0;
    padding: 0;
    font-size: 16px;
}

@media screen and (max-width: 600px) {
    .navbar-nav .dropdown-menu {

        font-size: 12px;
    }

}

.navbar-brand {
    letter-spacing: 0.5px;
}

.bg-blue {
    background-color: #192f59;
}

.dropdown-menu-dark {
    background-color: #192f59;
}

@media all and (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: none;
    }

    .navbar .nav-item:hover .dropdown-menu {
        display: block;
    }

    .navbar .nav-item .dropdown-menu {
        margin-top: 0;
    }
}

.main-cnt {
    background-color: white;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 5px;
}


.navBottom img {
    height: 490px;
}

@media screen and (max-width:770px) {

    .logo-text h3 {
        font-size: 20px;
    }

    .logo-text h6 {
        font-size: 15px;
    }

    .navTop img {
        width: 85px;
        height: 65px;
    }
}

@media screen and (max-width:530px) {

    .logo-text h3 {
        font-size: 13px;
    }

    .logo-text h6 {
        font-size: 10px;
    }

    .navTop img {
        width: 60px;
        height: 50px;
    }

    .navbar-brand {
        font-size: 1rem;
    }
}

/* Navbar Css End */








/* Footer Css Start */

.footer-middle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #a2b6dc85;
    color: white;
    /* border-radius: 5px; */
}

.footer-card {
    display: flex;
}

.footer-card p {
    margin-top: 4px;
    padding: 0 5px;
    font-style: italic;
    margin-bottom: 5px;
    text-decoration: none;
    color: green;
    /* font-weight: bold; */
}

.footer-card a {
    /* margin-top: 4px;*/
    padding: 0 5px;
    margin-bottom: 0px;
    text-decoration: none;
    color: green;
    /* font-weight: bold; */
    transition: all 0.4s ease-in-out;
    border-bottom: 6px solid #95959500;
}

.footer-card a:hover {
    color: #192f59;
    /* text-decoration: underline; */
    border-bottom: 6px solid #12d97c85;
}

.ashish:hover,
.dev-harshit:hover {
    color: #192f59;
    cursor: pointer;
    /* font-weight: bold; */
    text-decoration: underline;

}

@media screen and (max-width: 992px) {

    .footer-middle {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        font-size: 12px;
    }

    .footer-card p {
        margin-top: 0;
        padding: 0 5px;
        font-style: italic;
        /* margin-bottom: 5px; */
    }

    .footer-card a {
        margin-top: 0;
        padding: 0 5px;
        font-style: italic;
        margin-bottom: 5px;
        text-decoration: none;

    }

    .navBottom img {
        height: 200px;
    }

}

/* Footer Css End */







/* About Department Css Start */

.about-department {
    display: flex;
    flex-wrap: wrap;
}

.card {
    margin: 15px;
}

.about-department .card {
    width: 46%;
    line-height: 1.7;
}

.about-box-shadow {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.about-card-body {
    padding: 0;
}

.about-crm {
    border: none;
}

.about-department .card-title {
    color: white;
    margin: auto;
    font-weight: bold;
    background-color: #192f59;
    font-size: 1.2rem;
    padding: 8px 10px;
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    /* letter-spacing: 2px; */
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
}

.card-text {
    text-align: justify;
    margin-top: 5px;
}

.it-about-para {
    padding: 0 1rem;
    padding-bottom: 12px;
}


.about-main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}


.main-card {
    border: 2px solid #192f59;
    line-height: 1.2;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 1px 1px;
    min-height: 250px;
    box-shadow: 0 2px 14px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
}

.main-card h5 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #192f59;
    color: white;
    padding: 3px 0px 8px 0px;
    border-radius: 10px 10px 0px 0px;
    /* letter-spacing: 1px; */
}

.main-card h5:hover {
    filter: brightness(1.3);
}

.main-card ul li,
.main-card ol li {
    margin-top: 10px;
    padding-right: 5px;
}


@media screen and (max-width: 1200px) {
    .about-department .card {
        width: 100%;
    }
}


@media screen and (max-width: 570px) {
    .about-main {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
    }

    .card {
        margin: 10px 0;
    }

    .about-box-shadow {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
}

/* About Department Css End */







/* Card Group Css Start */

.card-wrap {
    background-color: rgb(225 230 235 / 66%);
    margin-bottom: 10px;
    margin-top: 10px;
}

.card-wrap h4 {
    display: flex;
    justify-content: center;
    font-weight: bold;
    padding-top: 15px;
    font-size: 1.7rem;
    color: #0f4c7a;
}

.card-wrap h5 {
    font-weight: bold;
}

.card-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card-flex .card {
    border: 5px solid whitesmoke;
}

.card-flex .card img {
    width: 100%;
    height: 250px;
}

.responsiveCard {
    width: 25rem;
}

@media screen and (max-width:800px) {

    body {
        font-size: 1rem;
    }

    .card-wrap h4 {
        font-size: 1rem;
    }

    .card-wrap h5 {
        font-weight: bold;
        font-size: 1.1rem;
    }

    .responsiveCard {
        width: 100%;
    }

    .card-flex .card img {
        width: 100%;
        height: 250px;
    }

}

/* Card Group Css End */




/* Faculty Carousal Css Start */

.faculty-card {
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    width: 40rem;
    border-radius: 10px;
    border: 5px solid whitesmoke;
}

.faculty-card img {
    width: 20rem;
    height: 300px;
    border: 2px solid whitesmoke;
}

.faculty-card .card-body h5 {
    font-weight: bold;
    color: #0f4c7a;
}

.faculty-control-arrow {
    background-color: #d5cfdb;
    border-radius: 50%;
}

.carousel-inner {
    background-color: rgb(246 246 246);
}

.carousel-inner h4 {
    display: flex;
    justify-content: center;
    font-weight: bold;
    padding-top: 15px;
    font-size: 1.7rem;
    color: #6fb374;
    color: #0f4c7a;
    letter-spacing: 1px;
}


@media screen and (max-width: 992px) {
    .faculty-carousal-display {
        display: none;
    }
}

/* Faculty Carousal Css End */

/* .card .go-corner {
    opacity: 0.7;
} */

/* .hover-xz:hover {
    border: 1px solid #00838d;
    background-color: gray;
    box-shadow: 0px 0px 999px 999px rgba(255, 255, 255, 0.5);
    z-index: 500;
} */

.hover-xz:hover p {
    color: #00838d;
}

/* .card:hover .go-corner {
    transition: opactiy 0.3s linear;
    opacity: 1;
} */

/* .card1 {
    display: block;
    position: relative;
    max-width: 262px;
    background-color: #f2f8f9;
    border-radius: 4px;
    padding: 32px 24px;
    margin: 12px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
} */

.hover-xz:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #00838d;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
}

.hover-xz:hover:before {
    transform: scale(21);
}

.hover-xz:hover p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
}

.hover-xz:hover h5 {
    transition: all 0.3s ease-out;
    color: #fff;
}